import {$} from 'dom7/dist/dom7.modular';

let _internals = {
    getCanonicalUrl: function () {
        let url = document.querySelector('body').getAttribute('data-page-url');

        if (url) {
            return url;
        }

        let link = document.querySelector("link[rel='canonical']");
        let canonical = window.location.href;
        if (link !== null) {
            canonical = link.href;
        }

        return canonical.replace(window.location.origin, '');
    },

    getPageType: function () {
        return document.querySelector("body").getAttribute('data-page-type');
    },

    getProductData: function (data) {
        let info = {
            id: data.sku,
            name: data.name,
            brand: data.brand,
            category: data.category,
            quantity: 1,
            price: data.price
        };

        if (data.list_name !== undefined) {
            info.list_name = data.list_name;
        }

        if (data.index !== undefined) {
            info.list_position = data.index;
        }

        if (data.position !== undefined) {
            info.list_position = data.position;
        }

        return info;
    },
    getFbProductData: function (data) {
        let list_name = data.category;
        if (data.list_name !== undefined) {
            list_name = data.list_name;
        }

        return {
            content_name: data.name,
            content_category: list_name,
            content_ids: data.sku,
            content_type: 'product',
            value: data.price,
            currency: 'RUB'
        };
    },
    trackProductView: function (elements) {
        let items = [];
        elements.each(function (index, el) {
            let data = $(el).data();
            items.push(_internals.getProductData(data));
            window.fbq('track', 'ViewContent', {
                content_ids: data.sku,
                content_type: 'product'
            });
        });

        if (items !== []) {
            gtag('event', 'view_item', {
                "items": items
            });
        }
    },
    trackProductList: function (elements, list_name) {
        let items = [];
        elements.each(function (index, el) {
            let data = $(el).data();
            data.list_name = list_name;
            items.push(_internals.getProductData(data));

            if (items.length === 10) {
                gtag('event', 'view_item_list', {
                    "items": items
                });
                items = [];
            }
        });

        if (items !== []) {
            gtag('event', 'view_item_list', {
                "items": items
            });
        }
    },
    initGA: function () {
        let GA_ID = document.querySelector('body').getAttribute('data-ga-id');

        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());

        gtag('config', GA_ID, {
            'transport_type': 'beacon',
            'page_path': _internals.getCanonicalUrl(),
            'dimension1': _internals.getPageType(),
            'custom_map': {
                'dimension1': 'dimension1'
            },
        });

        $(document).on('click', 'a.track-click', function () {
            let data = $(this).closest('.track').data();

            gtag('event', 'select_content', {
                "content_type": "product",
                "items": [
                    _internals.getProductData(data)
                ]
            });
        });
    },
    initYM: function () {
        let s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://mc.yandex.ru/metrika/tag.js';
        let ss = document.getElementsByTagName('script')[0];
        ss.parentNode.insertBefore(s, ss);
    },
    initFbPixel: function (pixelId) {
        !function (f, b, e, v, n, t, s) {
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window,
            document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', pixelId); // Insert your pixel ID here.
        fbq('track', 'PageView');
    },
    reachGoal: function (goal_id) {
        let YM_ID = document.querySelector('body').getAttribute('data-ym-id');
        ym(YM_ID, 'reachGoal', goal_id);
    }
};


export const getProductData = (data) => _internals.getProductData(data);
export const trackProductList = (elements, list_name) => _internals.trackProductList(elements, list_name);
export const trackProductView = (elements) => _internals.trackProductView(elements);
export const reachGoal = (goal_id) => _internals.reachGoal(goal_id);
export const getFbProductData = (data) => _internals.getFbProductData(data);

export const init = () => {
    let GA_ID = document.querySelector('body').getAttribute('data-ga-id');
    if (GA_ID) {
        _internals.initGA();
    }

    let YM_ID = document.querySelector('body').getAttribute('data-ym-id');
    if (YM_ID > 0) {
        _internals.initYM();
    }

    let FB_PIXEL_ID = document.querySelector('body').getAttribute('data-fb-pixel-id');

    if (FB_PIXEL_ID) {
        _internals.initFbPixel(FB_PIXEL_ID);
    }
};

import 'svgxuse';
import 'intersection-observer';
import '@grubersjoe/slide-menu/dist/slide-menu.ie';
import {
    $, appendTo, on, val, html, is, data, parent, parents, append, each, animate, offset,
    addClass, find, removeClass, toggleClass, closest, trigger, eq, remove, next, prop
} from 'dom7/dist/dom7.modular';
import AutoComplete from '@tarekraafat/autocomplete.js';
import GLightbox from 'glightbox'
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

$.fn['appendTo'] = appendTo;
$.fn['on'] = on;
$.fn['val'] = val;
$.fn['html'] = html;

$.fn['is'] = is;
$.fn['data'] = data;
$.fn['parent'] = parent;
$.fn['parents'] = parents;
$.fn['append'] = append;
$.fn['addClass'] = addClass;
$.fn['find'] = find;
$.fn['removeClass'] = removeClass;
$.fn['toggleClass'] = toggleClass;
$.fn['closest'] = closest;
$.fn['trigger'] = trigger;
$.fn['eq'] = eq;
$.fn['remove'] = remove;
$.fn['next'] = next;
$.fn['prop'] = prop;
$.fn['each'] = each;
$.fn['animate'] = animate;
$.fn['offset'] = offset;

const axios = require('axios');

let tracking = require('./tracking');

let internals = {
    init: function () {
        let hamburger = document.querySelector(".hamburger");

        if (hamburger == null) return;

        let menu = document.getElementById("nav_main");
        hamburger.addEventListener("click", function () {
            hamburger.classList.toggle("is-active");
            menu.classList.toggle("nav__wrap--active");
            document.body.classList.toggle('nav-opened');
        });

        document.querySelectorAll(".menu__item").forEach(function (el) {
            el.addEventListener('click', function () {
                hamburger.classList.remove("is-active");
                menu.classList.remove("nav__wrap--active");
                document.body.classList.remove('nav-opened');
            });
        });

        const menuElement = document.getElementById('menu_header');

        if (window.innerWidth < 1366) {
            const slideMenu = new SlideMenu(menuElement);
            slideMenu.open();
        }

        let btnSearch = document.getElementById("btn_search");
        let fieldSearch = document.getElementById("field_search");
        btnSearch.addEventListener("click", function () {
            fieldSearch.classList.toggle("nav__search_field--active");
        });

        window.addEventListener('hashchange', internals.scrollToCurrent);
        internals.scrollToCurrent();

        internals.initFormValidation();
        internals.initHeaderScroll();
        internals.initLazyImages();
        internals.initModals();
        internals.initSearch();
        internals.initGlobals();
        tracking.init();
        if (document.querySelector('body').getAttribute('data-mango')) {
            internals.initMango();
        }

        const closeTopLineButton = document.getElementById('closeTopLine');

        if (closeTopLineButton) {
            closeTopLineButton.addEventListener("click", function () {
                document.getElementById('topLine').remove();
                menu.classList.remove("nav__wrap--with-top-line");
                document.body.classList.remove('with-top-line');
                document.cookie = closeTopLineButton.getAttribute('data-cookie') + '=1;path=/';
            });
        }
    },
    scrollToCurrent: function () {
        let slice = window.location.hash.slice(1);

        if (!slice) return;

        let match = document.getElementById(slice);

        if (match) {
            let rect = match.getBoundingClientRect();
            let anchorOffset = window.pageYOffset + rect.top - 80;
            window.scrollTo(window.pageXOffset, anchorOffset);
        }
    },
    initGlobals: function () {
        // Setup sentry
        window.sentry_options = {
            dsn: document.querySelector('body').getAttribute('data-sentry-dsn'),
            release: document.querySelector('body').getAttribute('data-sentry-release'),
            environment: document.querySelector('body').getAttribute('data-sentry-environment')
        };


        let USER_ID = document.querySelector('body').getAttribute('data-user-id');

        if (USER_ID) {
            window.sentry_user_context = {
                email: document.querySelector('body').getAttribute('data-user-email'),
                id: USER_ID
            };
        } else {
            window.sentry_user_context = null;
        }

        // Setup global data layer
        window.dataLayer = window.dataLayer || [];

        window.gtag = function () {
            dataLayer.push(arguments);
        };

        // Metrika
        let YM_ID = document.querySelector('body').getAttribute('data-ym-id');

        window.ym = window.ym || function () {
            (window.ym.a = window.ym.a || []).push(arguments)
        };

        ym(YM_ID, "init", {
            clickmap: true,
            trackLinks: true,
            webvisor: document.querySelector('body').getAttribute('data-ym-webvisor'),
            accurateTrackBounce: true,
            ecommerce: "dataLayer",
            trackHash: true
        });

        // FB Pixel
        let FB_PIXEL_ID = document.querySelector('body').getAttribute('data-fb-pixel-id');

        if (FB_PIXEL_ID) {

            var n = window.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!window._fbq) window._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
        }
    },
    initFormValidation: function () {
        let form = $('form[data-init-fv="true"]');

        if (form.length < 1) {
            return;
        }

        import(/* webpackChunkName: "validation" */'./validation').then(function (validation) {
            for (let i = 0; i < form.length; i++) {
                const frm = form[i];

                $(frm).find('input[data-type="modal_url"]').val(document.location.href);
                $(frm).find('input[data-type="modal_page"]').val(document.title);

                let fv = validation.fv(
                    form[i],
                    {
                        plugins: {
                            declarative: new validation.DeclarativePlugin(),
                            submitButton: new validation.SubmitButtonPlugin(),
                            bootstrap: new validation.BootstrapPlugin({
                                rowSelector: '.popup__form-group'
                            }),
                            trigger: new validation.TriggerPlugin(),
                            recaptcha: new validation.RecaptchaPlugin({
                                element: frm.getAttribute('data-fvp-recaptcha___element'),
                                language: frm.getAttribute('data-fvp-recaptcha___language'),
                                message: frm.getAttribute('data-fvp-recaptcha___message'),
                                siteKey: frm.getAttribute('data-fvp-recaptcha___site-key'),
                                theme: frm.getAttribute('data-fvp-recaptcha___theme'),
                                size: frm.getAttribute('data-fvp-recaptcha___size'),
                                badge: frm.getAttribute('data-fvp-recaptcha___badge')
                            }),
                        }
                    }
                ).on('core.form.valid', function () {
                    internals.submitForm(fv.getFormElement()).then(function (response) {
                        const form = $(frm);

                        if (form.data('goal')) {
                            let tracking = require('./tracking');
                            tracking.reachGoal(form.data('goal'));
                        }

                        if (form.data('ga-goal')) {
                            gtag('event', form.data('ga-goal'), {
                                eventAction: 'click'
                            });
                        }

                        const redirect = form.data('redirect-to');
                        if (redirect) {
                            window.location.href = redirect;
                        } else {
                            form.html(response.message);
                        }

                    }).catch(function (response) {

                    });
                });
            }
        });
    },
    initModals: function () {
        let modals = require('./modals.js');
        modals.init();
    },
    initHeaderScroll: function () {
        let backBtn = document.getElementById('back');
        let header = document.getElementById('header');
        let nav = document.getElementById('nav_main');

        //Стиль шапки при скролле
        window.addEventListener('scroll', function () {
            let height = window.pageYOffset;
            if (height > 200) {
                backBtn.classList.add('visible');
            } else {
               backBtn.classList.remove('visible');
            }

            if (height > 100) {
                header.classList.add('header__main_scroll');
                nav.classList.add('nav__scroll');
            } else {
                header.classList.remove('header__main_scroll');
                nav.classList.remove('nav__scroll');
            }
        });

        // On load
        let height = window.pageYOffset;
        if (height > 100) {
            header.classList.add('header__main_scroll');
            nav.classList.add('nav__scroll');
        }

        if (height > 200) {
            backBtn.classList.add('visible');
        } else {
            backBtn.classList.remove('visible');
        }

        document.getElementById("back").addEventListener('click', function (e) {
            e.preventDefault();
            internals.scrollTo(document.body, 0, 800);
        });

    },
    initLazyImages: function () {
        let images = require('./image.js');
        images.init();
    },
    initSearch: function () {
        new AutoComplete({
            data: {                              // Data src [Array, Function, Async] | (REQUIRED)
                src: async () => {
                    // User search query
                    const query = document.querySelector("#field_search").value;
                    // Fetch External Data Source
                    const response = await axios.get(`/api/product/search?query=${query}`);
                    // Return Fetched data
                    return response.data.suggestions;
                },
                key: ["view"],
                cache: false
            },
            trigger: {
                event: ["input", "focusin", "focusout"]
            },
            placeHolder: "Поиск...",     // Place Holder text                 | (Optional)
            selector: "#field_search",           // Input field selector              | (Optional)
            threshold: 3,                        // Min. Chars length to start Engine | (Optional)
            debounce: 300,                       // Post duration for engine to start | (Optional)
            searchEngine: function (query, record) {
                return record;
            },              // Search Engine type/mode           | (Optional)
            resultsList: {                       // Rendered results list object      | (Optional)
                render: true,
                destination: document.querySelector("#search_results"),
                position: "beforeend",
                element: "ul"
            },
            maxResults: 8,                         // Max. number of rendered results | (Optional)
            highlight: true,                       // Highlight matching results      | (Optional)
            onSelection: item => {             // Action script onSelection event | (Optional)
                window.location.href = item.selection.value.data;
            }
        });

        window.onclick = function (event) {
            if (!$(event.target).parents('#search_results').length) {
                $('#field_search').val('').trigger('change');
            }
        }
    },
    serialize: function (form) {
        // Setup our serialized data
        let serialized = [];

        // Loop through each field in the form
        for (let i = 0; i < form.elements.length; i++) {

            let field = form.elements[i];

            // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            // If a multi-select, get all selections
            if (field.type === 'select-multiple') {
                for (let n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
                }
            }

            // Convert field data to a query string
            else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
            }
        }

        return serialized.join('&');
    },
    submitForm: function (form) {
        let submitButton = form.querySelector('button[type=submit]');

        let disabled = submitButton.getAttribute('disabled');

        if (disabled === 'disabled') {
            return Promise.resolve();
        }

        submitButton.setAttribute('disabled', 'disabled');

        let uri = form.getAttribute('action');

        return axios({
            url: uri,
            method: 'post',
            data: internals.serialize(form)
        }).then(function (response) {
            return Promise.resolve(response.data);
        }).catch(function (e) {
            submitButton.removeAttribute('disabled');
            if (e.response) {
                if (e.response.status === 422) {
                    internals.renderFormErrors(form, e.response.data);
                    return Promise.reject(new Error("Form submission failed, Data: " + JSON.stringify(e)));
                } else {
                    return Promise.reject(new Error("Form submission failed, Code: " + e.response.status));
                }
            }
            return Promise.reject(new Error("Form submission failed: " + e.message));
        })
    },
    renderFormErrors: function (form, data) {
        let container = form.querySelector('.form-errors');
        container.classList.remove('hidden');
        container.innerHTML = '';
        for (let field in data) {
            container.innerHTML += `<div class="alert alert-danger">${data[field]}</div>`;
        }
    },
    initLightBox: function (selector) {
        new GLightbox({
            selector: selector
        });
    },
    initMango: function () {
        (function (w, d, u, i, o, s, p) {
            if (d.getElementById(i)) {
                return;
            }
            w['MangoObject'] = o;
            w[o] = w[o] || function () {
                (w[o].q = w[o].q || []).push(arguments)
            };
            w[o].u = u;
            w[o].t = 1 * new Date();
            s = d.createElement('script');
            s.async = 1;
            s.id = i;
            s.src = u;
            p = d.getElementsByTagName('script')[0];
            p.parentNode.insertBefore(s, p);
        }(window, document, '//widgets.mango-office.ru/widgets/mango.js', 'mango-js', 'mgo'));

        mgo({calltracking: {id: 16649, elements: [{"selector": ".mgo-number"}]}});
        mgo({calltracking: {id: 16663, elements: [{"selector": ".mgo-812number"}]}});
        //mgo({multichannel: {id: 3459}});
    },
    scrollTo: function (element, to, duration) {
        if (duration <= 0) {
            return;
        }
        let difference = element.pageYOffset - to;
        let perTick = difference / duration * 10;
        setTimeout(() => {
            element.pageYOffset = element.pageYOffset - perTick;
            if (element.pageYOffset === to) {
                return;
            }
            scrollTo(element, to, duration - 10);
        }, 10);
    }
};

export const init = internals.init;
export const submitForm = internals.submitForm;
export const initLightBox = internals.initLightBox;

import {$} from 'dom7/dist/dom7.modular';

let common = require('./common');
const axios = require('axios');

window.fillEmail = function () {
    $('#subscribe_email').val($('#subscribe_email_footer').val());
};

let container = null;
let isAjaxModal = false;
let _internals = {
    cleanup: function () {
        if (isAjaxModal && container != null) {
            let form = container.find('form');
            if (form.length !== 0) {
                let fv = form.data('formValidation');
                if (fv) {
                    fv.destroy();
                }
            }

            setTimeout(function () {
                container.remove();
                container = null;
            }, 600);
        }
    },
    closeModal: function (e) {
        if (container == null) {
            return;
        }

        let name = "closed";

        if (e !== undefined) {
            let t = $(e.target);
            name = t.data("close-event-name");

            if (name === undefined) {
                name = "closed";
            }
        }
        container.removeClass("popup__wrap--open");
        $('body').removeClass('is-locked');
        container.trigger("hidden.modal", {name: name});

        _internals.cleanup();
    },
    displayModalAndBindActions: function (options) {
        container.addClass("popup__wrap--open");
        $('body').addClass('is-locked');

        if (typeof (options.onShow) !== 'undefined') {
            if (typeof (options.onShow) == "string") {
                window[options.onShow](container);
            } else {
                options.onShow(container);
            }
        }

        container.on('hidden.modal', function (e, param) {
            if (typeof (options.onClose) !== 'undefined') {
                options.onClose(container, param);
            }
        });

        let form = container.find('form');

        if (form.length === 0) {
            return;
        }

        import(/* webpackChunkName: "validation" */'./validation').then(function (validation) {
            let fv = validation.fv(
                form[0],
                {
                    plugins: {
                        declarative: new validation.DeclarativePlugin(),
                        submitButton: new validation.SubmitButtonPlugin(),
                        bootstrap: new validation.BootstrapPlugin({
                            rowSelector: '.popup__form-group'
                        }),
                        trigger: new validation.TriggerPlugin(),
                        recaptcha: new validation.RecaptchaPlugin({
                            element: form[0].getAttribute('data-fvp-recaptcha___element'),
                            language: form[0].getAttribute('data-fvp-recaptcha___language'),
                            message: form[0].getAttribute('data-fvp-recaptcha___message'),
                            siteKey: form[0].getAttribute('data-fvp-recaptcha___site-key'),
                            theme: form[0].getAttribute('data-fvp-recaptcha___theme'),
                            size: form[0].getAttribute('data-fvp-recaptcha___size'),
                            badge: form[0].getAttribute('data-fvp-recaptcha___badge')
                        }),
                    }
                }
            ).on('core.form.valid', function () {
                common.submitForm(fv.getFormElement()).then(function (response) {
                    if (typeof (options.onFormSendSuccess) !== 'undefined') {
                        options.onFormSendSuccess(response, container);
                    } else {
                        form.html(response.message);

                        if (form.data('goal')) {
                            let tracking = require('./tracking');
                            tracking.reachGoal(form.data('goal'));
                        }

                        if (form.data('ga-goal')) {
                            gtag('event', form.data('ga-goal'), {
                                eventAction: 'click'
                            });
                        }
                    }
                }).catch(function (response) {
                    if (typeof (options.onFormSendError) !== 'undefined') {
                        options.onFormSendError(response, container);
                    }
                });
            });
        });

    },
    showModal: function (target, options) {
        let $target = $(target);
        let modalTarget = $target.data('target');

        if (modalTarget !== undefined) {
            container = $(modalTarget);
            if (container.length) {
                isAjaxModal = false;
                _internals.displayModalAndBindActions(options);
                return;
            }
        }

        let url = $target.data('remote');
        _internals.loadUrl(url, options);
    },
    loadUrl: function (url, options) {
        _internals.cleanup();
        container = $('<div class="popup__wrap">');
        container.appendTo('body');
        isAjaxModal = true;

        axios({
            responseType: 'text',
            url: url,
            method: 'get'
        }).then(function (response) {
                if (typeof (options.beforeShow) !== 'undefined') {
                    options.beforeShow(response.data);
                }
                container.html(response.data);
                _internals.displayModalAndBindActions(options);
            }
        );
    },
    fillModalInputs: function () {
        $('#modal_url').val(document.location.href);
        $('#modal_page').val(document.title);
    }
};

export function init() {
    $(document).on('click', '[data-action=load-modal]', function (e) {
        e.preventDefault();
        let options = {};
        if ($(this).data('on-show') != null) {
            options.onShow = $(this).data('on-show');
        } else {
            options.onShow = _internals.fillModalInputs;
        }
        _internals.showModal(this, options);
    });

    $(document).on('click', '.popup__close, [data-action="popup-close"]', _internals.closeModal);

    $(document).on('click', '.popup__wrap', function (e) {
        if (!$(e.target).closest('.popup__box').length) {
            _internals.closeModal();
        }
    });
}

export function showModal(target, options) {
    _internals.showModal($(target), options);
}

export function loadUrl(url, options) {
    _internals.loadUrl(url, options);
}
